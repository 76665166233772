import React, { useState, useEffect } from 'react';
import { Select, MenuItem } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function createData(
    name: string,
    address: string,
    rooms: number,
    tenants: number,
    paymentStatus: string
) {
    return { name, address, rooms, tenants, paymentStatus };
}

const rows = [
    createData('Reliable Residency', 'HSR Layout phase 1', 6, 3, 'pending'),
    createData('Bhagat Apartments', 'Kadubesanhalli panathur Main Road', 9, 7, 'completed'),
    createData('Chandra dynasty', '2nd Phase, Sarjapur Road', 15, 12, 'upcoming'),
    createData('Aman Homes PG', 'Tin factory, Bangalore', 7, 7, 'pending'),
];


const PaymentTable = () => {
    const [filteredData, setFilteredData] = useState(rows);
    const [paymentStatus, setPaymentStatus] = useState<string>('all');

    useEffect(() => {

        setFilteredData(paymentStatus === 'all' ? rows : rows.filter(row => row.paymentStatus === paymentStatus))

    }, [paymentStatus]);

    return (
        <div className="dashboard-container">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <h2>Payment Details</h2>
                    <p className="property-number-text">You can view pending, upcoming and completed payments here.</p>
                </div>
                <div>
                    {/* <Link to="/owner/addproperty">
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            sx={{ textTransform: 'initial' }}
                        >
                            Add Property
                        </Button>
                    </Link> */}
                </div>
            </div>
            <br />
            <FormControl>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ width: 200, height: 45, marginBottom: 3 }}
                    value={paymentStatus}
                    label="Status"
                    onChange={(e) => setPaymentStatus(e.target.value)}
                >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={'pending'}>Pending</MenuItem>
                    <MenuItem value={'upcoming'}>Upcoming</MenuItem>
                    <MenuItem value={'completed'}>Completed</MenuItem>
                </Select>
            </FormControl>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableHead>
                        <TableRow
                            sx={{ 'th': { fontWeight: 600, color: '#6d7174' } }}
                        >
                            <TableCell>Property Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Rooms</TableCell>
                            <TableCell>Tenants</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    'td': { fontSize: 13 },
                                    'th': { fontWeight: 600, color: '#495259' },
                                    'th, td': { borderBottom: '1px solid rgb(250 244 244)' }
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.address}</TableCell>
                                <TableCell>{row.rooms}</TableCell>
                                <TableCell>{row.tenants}</TableCell>
                                <TableCell><MoreHorizIcon /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default PaymentTable;