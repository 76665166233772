import React from "react";
import { NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { HashLink as Link } from "react-router-hash-link";
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';

import Login from '../landing/Login';

import "./index.css";
import renteaLogo from "../images/rentea-logo.png";

export default function Navbar() {

  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
        setState({ ...state, right: open });
      };

    
  return (
    <div className="nav-mid">
      <nav className="navbar navbar-expand-lg navbar-light bg-white custom-nav">
        <div className="nav-brand">
          <LazyLoad>
            <img className="brand-img" src={renteaLogo} alt="rentealogo" />
          </LazyLoad>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light bg-white ">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#toggle"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>

        <div
          id="toggle"
          className="collapse navbar-collapse justify-content-lg-end"
        >
          <ul className="navbar-nav">
            <li className="nav-item" style={{ color: "#109fda" }}>
              <NavLink className="nav-link items" to="/">
                HOME
              </NavLink>
            </li>
            <li className="nav-item">
              <Link className="nav-link items" to="#howItWorks">
                ABOUT US
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link items" to="#talkToUs">
                CONTACT US
              </Link>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link items" to="/">
                +91 87893 30638
              </NavLink>
            </li>
            <li className="nav-item">
              <Button variant="outlined" onClick={toggleDrawer(true)}>
                Owner Login
              </Button>
              <Drawer
                anchor={"right"}
                open={state.right}
                onClose={toggleDrawer(false)}
              >
                <Login toggleDrawer={toggleDrawer} />
              </Drawer>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
