import React from 'react';
import { Route, Switch } from "react-router-dom";
import PaymentTable from '../payments/PaymentTable';
import AddPropertyForm from '../properties/AddPropertyForm';
import PropertyDetails from '../properties/PropertyDetails';
import PropertyList from '../properties/PropertyList';
import TenantTable from '../tenants/TenantTable';

import LeftDrawer from './LeftDrawer';

const Documents = () => <div>Documents</div>;

const OwnerLayout = () => <div style={{ display: 'flex' }}>
    <LeftDrawer>
        <Switch>
            {/*Side Nav Routes*/}
            <Route exact path="/owner/" component={PropertyList} />
            <Route exact path="/owner/payments" component={PaymentTable} />
            <Route exact path="/owner/tenants" component={TenantTable} />
            <Route exact path="/owner/documents" component={Documents} />

            {/*Additional Routes */}
            <Route exact path="/owner/addproperty" component={AddPropertyForm} />
            <Route exact path="/owner/property/:propertyId" component={PropertyDetails} />
        </Switch>
    </LeftDrawer>
</div>;

export default OwnerLayout;