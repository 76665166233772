import React from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

const AddPropertyForm = () => {
    return(
        <div className="dashboard-container">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <h3>Add Property</h3>
                </div>
                <div>
                    <Link to="/owner">
                        <Button
                            variant="outlined"
                            startIcon={<ArrowBackIcon />}
                            sx={{ textTransform: 'initial' }}
                        >
                            Back
                        </Button>
                    </Link>
                </div>
            </div>
            <p>Add the form for Add Building here...</p>
        </div>
    );
}

export default AddPropertyForm;