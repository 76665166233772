import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

import PropertyTable from './PropertyTable';


const PropertyList = () => {

    return (
        <div className="dashboard-container">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <h2>Properties</h2>
                    <p className="property-number-text">You have 3 properties.</p>
                </div>
                <div>
                    <Link to="/owner/addproperty">
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            sx={{ textTransform: 'initial' }}
                        >
                            Add Property
                        </Button>
                    </Link>
                </div>
            </div>
            <br />
            <PropertyTable/>
        </div>
    );
};

export default PropertyList;