import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useHistory } from 'react-router-dom';

function createData(
    propertyId: number,
    name: string,
    address: string,
    rooms: number,
    tenants: number,
) {
    return { propertyId, name, address, rooms, tenants };
}

const rows = [
    createData(1,'Reliable Residency', 'HSR Layout phase 1', 6, 3),
    createData(2,'Bhagat Apartments', 'Kadubesanhalli panathur Main Road', 9, 7),
    createData(3,'Chandra dynasty', '2nd Phase, Sarjapur Road', 15, 12),
    createData(4,'Aman Homes PG', 'Tin factory, Bangalore', 7, 7),
];

const PropertyTable = () => {
    const history = useHistory();

    return (
        <div>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                    <TableRow
                        sx={{ 'th': { fontWeight: 600, color: '#6d7174' } }}
                    >
                        <TableCell>Property Name</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Rooms</TableCell>
                        <TableCell>Tenants</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={{ cursor: 'pointer' }}
                >
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                'td' : {fontSize: 13},
                                'th' : {fontWeight: 600, color: '#495259'},
                                'th, td' : {borderBottom: '1px solid rgb(250 244 244)'}
                            }}
                            onClick={() => history.push({
                                pathname: `/owner/property/${row.propertyId}`,
                                state: row
                            })}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell>{row.address}</TableCell>
                            <TableCell>{row.rooms}</TableCell>
                            <TableCell>{row.tenants}</TableCell>
                            <TableCell><MoreHorizIcon /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    );
};

export default PropertyTable;



// const CommonTable = ({ rows, headingList }: TableProps) => {
//     return (
//         <div>
//             <TableContainer component={Paper}>
//                 <Table sx={{ minWidth: 300 }} aria-label="simple table">
//                     <TableHead>
//                         <TableRow
//                             sx={{ 'th': { fontWeight: 600, color: '#6d7174' } }}
//                         >
//                             {headingList.map((heading) => (
//                                 <TableCell key={heading}>{heading}</TableCell>
//                             ))}
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {rows.map((row, i) => (
//                             <TableRow
//                                 key={row.name}
//                                 sx={{
//                                     '&:last-child td, &:last-child th': { border: 0 },
//                                     'td': { fontSize: 13 },
//                                     'th': { fontWeight: 600, color: '#495259' },
//                                     'th, td': { borderBottom: '1px solid rgb(250 244 244)' }
//                                 }}
//                             >
//                                 {Object.keys(row).map((obj, index) =>
//                                     <TableCell key={index}>{row[obj]}</TableCell>
//                                 )}
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </div>
//     );
// };