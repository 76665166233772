import React, { useState } from "react";
import { Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./index.css";

function TalkToUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const submitForm = (e: any) => {
    e.preventDefault();
    
    if(!name || !email || !phone || phone.length !== 10){
      alert("Enter all the fields properly!");
      return;
    }

    console.log("submitting form...");
    let xhr = new XMLHttpRequest();
    let url = 'https://api.hsforms.com/submissions/v3/integration/submit/24969527/579919d7-8979-4474-a990-61842f4123ae'

    let data = {
      "fields": [
        {
          "name": "firstname",
          "value": name
        },
        {
          "name": "email",
          "value": email
        },
        {
          "name": "phone",
          "value": phone
        },
        {
          "name": "message",
          "value": message
        }
      ],
      "context": {
        "pageUri": "www.rentea.in",
        "pageName": "RenTea"
      }
    }

    let final_data = JSON.stringify(data);

    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = function() {
      if(xhr.readyState === 4 && xhr.status === 200) {
        alert(xhr.responseText);
      }else if(xhr.readyState === 4 && xhr.status === 403){
        alert(xhr.responseText);
      }else if(xhr.readyState === 4 && xhr.status === 404){
        alert(xhr.responseText);
      }else if(xhr.readyState === 4 && xhr.status === 400){
        let resp = JSON.parse(xhr.responseText);
        alert(resp.errors[0].message);
      }
    }

    xhr.send(final_data);

  }
  return (
    <div className="talkToUs" id="talkToUs">
      <div className="heading text-center">
        <h2>Talk to us</h2>
      </div>
      <Row>
        <div className="col-md-2"></div>
        <div className="talkToUs-form col-md-4">
          <form onSubmit={submitForm}>
            <div className="form-body">
              <input
                className="form-content"
                type="text"
                placeholder="Your Name*"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-body">
              <input
                className="form-content"
                type="email"
                placeholder="Email*"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-body">
              <input
                className="form-content"
                type="text"
                placeholder="Phone*"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form-body">
              <textarea
                className="form-content message-field"
                rows={3}
                name="message"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div>
              <Button className="submit-btn" type="Submit">Submit</Button>
            </div>
          </form>
        </div>

        <div className="col-md-6">
          <div className="number">
            <p className="talkToUs-para">
              <FontAwesomeIcon
                className="envelope-icon"
                icon={faPhoneAlt}
                size="1x"
              />
              +91 87893 30638
            </p>
          </div>
          <div className="email">
            <p className="talkToUs-para">
              <FontAwesomeIcon
                className="envelope-icon"
                icon={faEnvelope}
                size="1x"
              />
              contact@rentea.in
            </p>
          </div>
          <div className="address">
            <p className="talkToUs-para">
              <FontAwesomeIcon
                className="envelope-icon"
                icon={faMapMarkerAlt}
                size="1x"
              />
              Banglore, India 860506
            </p>
          </div>
        </div>
      </Row>
    </div>
  );
}

export default TalkToUs;
