import React from "react";
import { Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import "./index.css";

function HowItWorks() {
  return (
    <div className="howItWorks" id="howItWorks">
      <div className="howItWorks-heading">
        <h2>
          How <span className="span-txt">RenTea</span> Works?
        </h2>
      </div>
      <Row>
        <div className="col-md-3"></div>
        <div className="howItWorks-content step1 col-md-6">
          <LazyLoad>
            <img
              className="howItWorks-img"
              src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626420684/HowItWorks-step1-img_bldswh.png"
              alt="HowItWorks_step1_img"
            />
          </LazyLoad>
          <p className="howItWorks-step1-para">
            Step 1: Owner Signup/Login into the app
          </p>
        </div>
      </Row>
      <Row>
        <div className=" col-md-6"></div>
        <div className="howItWorks-content step2 col-md-6">
          <p className="howItWorks-step2-para">
            Step 2: Owner add their Properties, Room and Tenant Info
          </p>
          <LazyLoad>
            <img
              className="howItWorks-img"
              src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626420684/HowItWorks-step2-img_pqygta.png"
              alt="HowItWorks_step2_img"
            />
          </LazyLoad>
        </div>
      </Row>
      <Row>
        <div className=" col-md-3"></div>
        <div className="howItWorks-content step3 col-md-6">
          <LazyLoad>
            <img
              className="howItWorks-img"
              src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626420684/HowItWorks-step3-img_bm5guv.png"
              alt="HowItWorks_step3_img"
            />
          </LazyLoad>
          <p className="howItWorks-step3-para">
            Step 3: Owner get all upcoming, pending payment info with notifications
          </p>
        </div>
      </Row>
      <Row>
        <div className="col-md-6"></div>
        <div className="howItWorks-content step4 col-md-6">
          <p className="howItWorks-step4-para">
            Step 4: Tenant get the app link & can log into the app and pay the rent, get receipt etc.
          </p>
          <LazyLoad>
            <img
              className="howItWorks-img"
              src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626420684/HowItWorks-step4-img_djetcl.png"
              alt="HowItWorks_step4_img"
            />
          </LazyLoad>
        </div>
      </Row>
    </div>
  );
}

export default HowItWorks;
