import { BrowserRouter, Route } from "react-router-dom";

import Landing from "./landing";
import OwnerLayout from "./owner/layout";

import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route exact path="/" component={Landing}></Route>
        <Route  path="/owner" component={OwnerLayout}></Route>

      </BrowserRouter>
    </div>
  );
}

export default App;
