import React from 'react';

const TenantTable = () => {
    return(
        <div className="dashboard-container">
            <h2>Tenant Table here..</h2>
        </div>
    );
}

export default TenantTable;