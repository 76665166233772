import React, { useState, ReactChildren, ReactChild } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ApartmentSharpIcon from '@mui/icons-material/ApartmentSharp';
import MonetizationOnSharpIcon from '@mui/icons-material/MonetizationOnSharp';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import PeopleOutlineSharpIcon from '@mui/icons-material/PeopleOutlineSharp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useHistory, Link } from "react-router-dom";
import renteaLogo from '../../images/rentea-logo.png';

import '../owner.css';


const drawerWidth = 240;

interface AuxProps {
    children: ReactChild | ReactChildren;
}

const LeftDrawer  = ({ children }: AuxProps) => {
    const history = useHistory();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const itemsList = [
        {
          text: "Properties",
          icon: <ApartmentSharpIcon />,
          onClick: () => history.push("/owner"),
          path: "/owner"
        },
        {
          text: "Payments",
          icon: <MonetizationOnSharpIcon />,
          onClick: () => history.push("/owner/payments"),
          path: "/owner/payments"
        },
        {
            text: "Tenants",
            icon: <PeopleOutlineSharpIcon />,
            onClick: () => history.push("/owner/tenants"),
            path: "/owner/tenants"
        },
        {
          text: "Documents",
          icon: <DescriptionSharpIcon />,
          onClick: () => history.push("/owner/documents"),
          path: "/owner/documents"
        }
    ];

    const drawer = (
        <div>
            <div className="drawer-logo" style={{ paddingTop: '20px' }} onClick={() => history.push('/')}>
                <img className="brand-img" src={renteaLogo} alt="rentealogo" />
            </div>
            <List>
                {itemsList.map((item, index) => (
                    <ListItem component={Link} key={item.text} to={item.path} className="listitem-link">
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText 
                            sx={{ color: 'initial' }}
                            primary={item.text} 
                        />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                <ListItem button key='Logout'>
                    <ListItemIcon>
                        <ExitToAppSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary='Logout' />
                </ListItem>
            </List>
        </div>
    );


    return (
        <>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="default"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <Typography variant="h6" noWrap component="div">
                        RenTea - Property Management
                    </Typography> */}
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <br /><br /><br />
            {children}
        </>
    );
};


export default LeftDrawer;