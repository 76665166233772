import React from "react";
import { Row } from "react-bootstrap";
import "./index.css";

function FeatureInNumber() {
  return (
    <div className="featureInNumber">
      <Row>
        <div className="featureInNumber-content col-md-3">
          <div>
            <img
              className="featureInNumber-img"
              src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626421201/registered-room-icon_dqkod0.png"
              alt="registered_room_icon"
            />
          </div>
          <div className="featureInNumber-num">1000+</div>
          <div className="featureInNumber-text">Registered rooms</div>
        </div>
        <div className="featureInNumber-content col-md-3">
          <div>
            <img
              className="featureInNumber-img"
              src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626421201/properties-listed-icon_rfd4es.png"
              alt="properties_listed_icon"
            />
          </div>
          <div className="featureInNumber-num">200+</div>
          <div className="featureInNumber-text">Properties listed</div>
        </div>
        <div className="featureInNumber-content col-md-3">
          <div>
            <img
              className="featureInNumber-img"
              src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626421201/verified-owners-icon_anqco3.png"
              alt="verified_owners_icon"
            />
          </div>
          <div className="featureInNumber-num">100+</div>
          <div className="featureInNumber-text">Verified owners</div>
        </div>
        <div className="featureInNumber-content col-md-3">
          <div>
            <img
              className="featureInNumber-img"
              src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626421201/transaction-done-icon_zce39g.png"
              alt="transaction_done_icon"
            />
          </div>
          <div className="featureInNumber-num">10Lac+</div>
          <div className="featureInNumber-text">Transactions done</div>
        </div>
      </Row>
    </div>
  );
}

export default FeatureInNumber;
