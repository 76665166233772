import React from "react";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhoneAlt,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import LazyLoad from "react-lazyload";
import "./index.css";
import privacy_policy from "../documents/privacy-policy.txt";
import refund_policy from "../documents/refund-policy.txt";
import terms_condition from "../documents/terms-condition.txt";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-body">
        <Row>
          <div className="col-md-1"></div>
          <div className="footer-content section1 col-md-3">
            <div className="footer-brand">
              <LazyLoad>
                <img
                  className="brand-img"
                  src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626421305/rentea-logo_mm2myg.png"
                  alt="renteaLogo"
                />
              </LazyLoad>
            </div>
            <div className="follow-us">
              <h6 className="footer-h6">Follow us</h6>
              <div className="social-icons">
                <FontAwesomeIcon
                  className="linkedin-icon"
                  icon={faLinkedin}
                  size="2x"
                />
                <FontAwesomeIcon
                  className="facebook-icon"
                  icon={faFacebook}
                  size="2x"
                />
                <FontAwesomeIcon
                  className="twitter-icon"
                  icon={faTwitter}
                  size="2x"
                />
                <FontAwesomeIcon
                  className="instagram-icon"
                  icon={faInstagram}
                  size="2x"
                />
              </div>
            </div>
            <div className="contact-us">
              <h6 className="footer-h6">Contact Us</h6>
              <p>
                <FontAwesomeIcon
                  className="phone-icon"
                  icon={faPhoneAlt}
                  size="1x"
                />
                +91 87893 30638
              </p>
              <p>
                <FontAwesomeIcon
                  className="envelope-icon"
                  icon={faEnvelope}
                  size="1x"
                />
                contact@rentea.in
              </p>
            </div>
            <div className="office-address">
              <h6 className="footer-h6">Office Address</h6>
              <p>
                <FontAwesomeIcon
                  className="map-icon"
                  icon={faMapMarkerAlt}
                  size="1x"
                />
                Banglore, India, 806560
              </p>
            </div>
          </div>

          <div className="footer-content section2 col-md-3">
            <div className="info">
              <p>HOME</p>
              <p>CONTACT US</p>
              <p>ABOUT US</p>

              <p>
                <a href={refund_policy} download>
                  REFUND POLICY
                </a>
              </p>
              <p>
                <a href={terms_condition} download>
                  TERMS AND CONDITIONS
                </a>
              </p>
              <p>
                <a href={privacy_policy} download>
                  PRIVACY POLICY
                </a>
              </p>
            </div>
          </div>

          <div className="footer-content section3 col-md-3">
            <div className="download-links">
              <p>DOWNLOAD THE APP</p>
              <div className="download-icons">
                <img
                  className="playstore-icon"
                  src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626421135/playstore-icon_d4qzii.png"
                  alt="playstore_icon"
                />
              </div>
              <div>
                <img
                  className="appstore-icon"
                  src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626421135/appstore-icon_watbvb.png"
                  alt="appstore_icon"
                />
              </div>
            </div>
          </div>
        </Row>
      </div>
      <div className="bottom-footer">
        <div>
          <h5>Copyright © 2021 - Rentea</h5>
        </div>
      </div>
    </div>
  );
}

export default Footer;
