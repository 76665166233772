import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import MuiPhoneNumber from 'material-ui-phone-number';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";

interface LoginProps{
    toggleDrawer : (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void
}


const Login = ({ toggleDrawer }: LoginProps) => {
    const history = useHistory();
    return (
        <Box
            sx={{
                width: {
                    sm: '100%',
                    md: 550,
                    lg: 500
                },
                p: 7
            }}
            role="presentation"
        >
            <CloseIcon className="close-icon" onClick={toggleDrawer(false)} />
            <div className="login-container">
                <h3>Login/Signup</h3>
                <div className="login-phone-container">
                    <MuiPhoneNumber
                        defaultCountry={'in'}
                        onChange={(e) => console.log(e)}
                        variant="outlined"
                        label="Enter Phone"
                        className="phone-input"
                    />
                </div>
                <Button variant="outlined" onClick={() => history.push('/owner')}>Login</Button>

                <p>Having trouble? Please contact <a href="/">support@rentea.in</a> for further support.</p>
            </div>
        </Box>
    );
};

export default Login;