import React from "react";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, Link, useLocation } from "react-router-dom";

interface PropertyInfo {
    propertyId: number,
    name: string,
    address: string,
    rooms: number,
    tenants: number,
}

interface ParamTypes {
    propertyId: string
}

const PropertyDetails = () => {

    const { propertyId } = useParams<ParamTypes>();
    const { state }: { state: PropertyInfo } = useLocation();

    return (
        <div className="dashboard-container">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <h2>Property {propertyId} Details</h2>
                    <p className="property-number-text">Adress: {state.address}</p>
                </div>
                <div>
                    <Link to="/owner">
                        <Button
                            variant="outlined"
                            startIcon={<ArrowBackIcon />}
                            sx={{ textTransform: 'initial' }}
                        >
                            Back
                        </Button>
                    </Link>
                </div>
            </div>
            <br />
            <h4>Name: {state.name}</h4>
        </div>
    );
}

export default PropertyDetails;