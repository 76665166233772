import React from "react";
import { Row } from "react-bootstrap";

import Navbar from "../navbar";
import ServiciableCities from "./ServiciableCities";
import HowItWorks from "./HowItWorks";
import FeatureInNumber from "./FeatureInNumber";
import TalkToUs from "./TalkToUs";
import Footer from "./Footer";
import "./index.css";
// import { FeaturesList } from "./FeaturesList";

function Landing() {
  return (
    <div>
      <Navbar />
      <div className="landing">
        <Row>
          <div className="landing-content col-md-12">
            <div>
              <h2 className="landing-heading">
                Manage your <span className="span-txt">Property and Rent </span>
                Hassle-free over a sip of tea.
              </h2>
              <p className="landing-para">
                RenTea enables Property Owners to avoid all the manual and paper work
                involved in managing Rent Payments, Receipt, Tenant details of multiple
                properties in one app.
              </p>
              {/* <ul>
                <li>No need to check Bank statement to verify Payment status</li>
              </ul> */}
            </div>

            {/* <div className="input">
              <input
                type="text"
                className="text-field"
                placeholder="Enter Phone Number"
              />
              <Button className="link-btn">Get App Link</Button>
            </div> */}
            <h6 className="dwnld-btn">Download App now to get Free Access:</h6>
            <br />
            <div className="dwnld-btn">
              <a 
                className="dwnld1" 
                href="https://play.google.com/store/apps/details?id=in.rentea.rentea"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626421137/playstore-download-icon_i2mbyp.png"
                  width="150px"
                  alt="playstore_download_icon"
                />
              </a>
              <img
                src="https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626421136/appstore-download-icon_bm3uby.png"
                width="150px"
                alt="appstore_download_icon"
                onClick={() => alert('Coming soon in IOS')}
              />
            </div>
          </div>
        </Row>
      </div>

      <ServiciableCities />
      {/* <FeaturesList /> */}
      <HowItWorks />
      <FeatureInNumber />
      <TalkToUs />
      <Footer />
    </div>
  );
}

export default Landing;
